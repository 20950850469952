<template>
  <main>
    <div class="column__wrapper">
      <div class="column column__left">
        <AccountMenu/>
      </div>
      <div class="column column__right">
        <h3>Mes Commandes</h3>
        <div v-if="listorders.length > 0" class="table__wrapper">
          <table>
            <thead>
            <tr>
              <th>Commande n°</th>
              <th>Date commande</th>
              <th>Total</th>
              <th>Détail/Facture</th>
            </tr>
            </thead>

            <tbody v-for="order in listorders">
            <tr>
              <td class="brown">{{ order.name }}</td>
              <td>{{ parseDate(order.commitment_date) }}</td>
              <td>{{ order.amount_total }} €</td>
              <td class="download">
                <button aria-label="download" class="button full width" @click="getOrderDetails(order.id)">télécharger</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>{{ $t('empty_order_list') }}</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {mapGetters} from "vuex";
import AccountMenu from "@/components/AccountMenu";
import {getAPI} from "@/services/axios-api";
import fileDownload from "js-file-download";

export default {
  name: "Invoice",
  components: {AccountMenu},
  computed: {
    ...mapGetters('auth', ['getUserId']),
  },
  mounted() {
    this.getOrders();
  },
  data() {
    return {
      listorders: [],
    }
  },
  methods: {
    async getOrders() {
      await getAPI
          .get('/sale/order/')
          .then(response => {
            this.listorders = response.data
          })
          .catch(errors => {
            console.log(errors)
          })
    },
    parseDate(date) {
      let d = new Date(date)
      let parseDate = d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear()
      return parseDate
    },
    getOrderDetails: async function (orderId) {
      let data = new FormData()
      data.set("sale_order", orderId)
      let route = ""
      await getAPI
          .post("/sale/report/", data, {
            responseType: 'blob',
          })
          .then(response => {
            fileDownload(response.data, `fdr.pdf`);
          })
      return route
    },
  },
}
</script>

<style lang="scss" scoped>

.table__wrapper {
  width: 100%;
  max-width: 100%;
  overflow: auto;
  padding: 0 3px 10px;
  scrollbar-color: $Brown #ddd;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: $Brown;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $LightBrown;
  }

  @media screen and (min-width: 992px) {
    scrollbar-color: $Brown #ddd;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      height: 5px;
    }
  }
}

table {
  width: 100%;
  margin: 0 auto;
  vertical-align: middle;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 10px;
  max-width: 100%;

  thead {
    th {
      font-size: 15px;
      font-weight: 600;
      padding: 3px 10px;
      white-space: nowrap;
    }
  }

  tbody {
    border-radius: 5px;

    tr {
      position: relative;

      &::after {
        @include position(0, 0);
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 1px #BDBDBD;
        border-radius: 5px;
      }

      td {
        padding: 3px 10px;
        font-weight: 600;
        background-color: #F9F9F9;
        color: $Black;
        white-space: nowrap;

        &.brown {
          color: $Brown;
        }

        &.download {
          z-index: 19;
          position: relative;
        }
      }
    }
  }
}
</style>